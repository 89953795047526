@keyframes marqueefy {
  0% {
    transform: translate(var(--mq-translate-x-0), var(--mq-translate-y-0));
  }

  100% {
    transform: translate(var(--mq-translate-x-100), var(--mq-translate-y-100));
  }
}

.marqueefy {
  --mq-bg: #f8f9fa;
  --mq-hover-bg: #e9ecef;
  --mq-color: #212529;
  --mq-hover-color: #000;
  --mq-border-width: 0;
  --mq-border-style: solid;
  --mq-border-color: transparent;
  --mq-border-radius: .375rem;
  --mq-padding-y: 1rem;
  --mq-padding-x: 0;
  --mq-font-size: 1rem;
  --mq-font-family: sans-serif;
  --mq-animation-duration: 15s;
  --mq-translate-x-0: 0;
  --mq-translate-x-100: -100%;
  --mq-translate-y-0: 0;
  --mq-translate-y-100: 0;
  --mq-item-gap: 1rem;

  box-sizing: border-box;
  display: inline-block;
  max-width: 100%;
  padding: var(--mq-padding-y) var(--mq-padding-x);
  overflow: hidden;
  background-color: var(--mq-bg);
  border: var(--mq-border-width) var(--mq-border-style) var(--mq-border-color);
  border-radius: var(--mq-border-radius);

  .content {
    display: inline-block;
    padding: 0 0 0 100%;
    margin: 0;
    font-family: var(--mq-font-family);
    font-size: var(--mq-font-size);
    color: var(--mq-color);
    white-space: nowrap;
    animation-name: marqueefy;
    animation-duration: var(--mq-animation-duration);
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    .item {
      margin: 0 var(--mq-item-gap) 0 0;
    }
  }

  &[data-mq-direction="right"] {
    --mq-translate-x-0: -100%;
    --mq-translate-x-100: 0;
  }

  &[data-mq-direction="top"],
  &[data-mq-direction="bottom"] {
    --mq-padding-y: 0;
    --mq-padding-x: 1rem;
    --mq-translate-x-0: 0;
    --mq-translate-x-100: 0;

    .content {
      padding: 0;
      white-space: normal;

      .item {
        display: block;
        margin: 0 0 var(--mq-item-gap) 0;
      }
    }
  }

  &[data-mq-direction="top"] {
    --mq-translate-y-0: var(--mq-height, 100%);
    --mq-translate-y-100: -100%;
  }

  &[data-mq-direction="bottom"] {
    --mq-translate-y-0: -100%;
    --mq-translate-y-100: var(--mq-height, 100%);
  }

  &:hover,
  &:focus-visible {
    color: var(--mq-hover-color);
    background-color: var(--mq-hover-bg);

    .content {
      animation-play-state: paused;
    }
  }

  &:focus-visible {
    outline-color: var(--mq-hover-color);
  }
}
